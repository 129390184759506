import { APICore } from "./apiCore";

const api = new APICore();


export function listInvoices(payload: any){
    const baseUrl = "invoice/list"; 
    const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
            return api.get(`${baseUrl}?${params}`)
    // return api.get(`invoice/list?limit=5&page=1`)
}

export function lookupofInvoicesNames(payload: any){
    const baseUrl = "invoice/list"; 
    const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
    return api.get(`${baseUrl}?${params}`)
}


export function downloadInvoices(payload: any){
    const baseUrl = "invoice/downloadInvoicePdf"; 
    return api.get(`${baseUrl}/${payload}`)
}

export function sentInvoices(payload: any){
    const baseUrl = "invoice/send"; 
    return api.get(`${baseUrl}/${payload}`)
}

export function getInvoiceById(payload: any){
    const baseUrl = "invoice/getInvoice"; 
    return api.get(`${baseUrl}/${payload}`)
}

export function createNewInvoice(payload: any){
    const baseUrl = "invoice/addInvoice"; 
    return api.create(`${baseUrl}`, payload)
}

export function CreateDebitNoteCreditNotForInvoice(payload: any){
    const baseUrl = "invoice/addInvoice"; 
    return api.create(`${baseUrl}`, payload)
}

export function updateInvoice(payload: any){
    const baseUrl = "invoice/update"; 
    return api.updatePatch(`${baseUrl}`, payload)
}

export function mergeInvoice(payload: any){
    const baseUrl = "invoice/merge"; 
    const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
   
    return api.updatePatch(`${baseUrl}?${params}`, {})
}

export function splitInvoice(payload: any,id:any){
    console.log("the payloda" , id, payload)
    const baseUrl = "invoice/split"; 
    return api.updatePatch(`${baseUrl}/${id}`, payload)
}


