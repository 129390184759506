import { APICore } from "./apiCore";

const api = new APICore();

// account
export function listSOA(payload:any) {
  const baseUrl = "invoice/soa/list";
  // const params =  Object.keys(payload)
  //           .map((key) => key + "=" + payload[key])
  //           .join("&")
if(payload?.hotel)
    return api.get(`${baseUrl}/${payload.hotel}?page=${payload.page}&limit=${payload.limit}`)
}


export function downloadSOA(payload: any){
  const baseUrl = "invoice/soa/downloadInvoice"; 
  return api.get(`${baseUrl}/${payload}`)
}

export function sentSOA(payload: any){
  const baseUrl = "invoice/soa/sendInvoice"; 
  return api.get(`${baseUrl}/${payload}`)
}

export function removeSOAById(payload: any){
  const baseUrl = "invoice/soa/removeInvoice"; 
  return api.updatePatch(`${baseUrl}/${payload}`,{})
}

