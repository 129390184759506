// nstants
import { OutletsActionTypes } from "./constants";


const INIT_STATE = {
  outletsloading: false,
  outletsList: [],
  page: 0,
  limit:10,
  selectedOutlets:{},
  outletUsersLoading: false
};


interface AuthActionType {
  type:
    | OutletsActionTypes.API_OUTLETS_RESPONSE_SUCCESS
    | OutletsActionTypes.API_OUTLETS_RESPONSE_ERROR
    | OutletsActionTypes.LIST_OUTLETS
    | OutletsActionTypes.CREATE_OUTLETS
    | OutletsActionTypes.RESET_OUTLETS
    | OutletsActionTypes.DELET_OUTLETS
    | OutletsActionTypes.ADD_OUTLET_USERS;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  outletsloading: boolean,
  outletsList: any[],
  page: number,
  limit:number,
  selectedOutlets:any,
  outletUsersLoading: boolean
}

const OutletsReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case OutletsActionTypes.API_OUTLETS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case OutletsActionTypes.LIST_OUTLETS: {
          return {
            ...state,
            outletsloading: false,
            outletsList: action.payload.data
          };
        }
        case OutletsActionTypes.SEARCH_OUTLETS_BY_HOTELS: {
          return {
            ...state,
            outletsloading: false,
            outletsList: action.payload.data
          };
        }
        
        case OutletsActionTypes.EDIT_OUTLETS_SELECTED: {
          return {
            ...state,
            outletsloading: false,
            selectedOutlets:action.payload.data
          };
        }
        
        case OutletsActionTypes.DELET_OUTLETS: {
          return {
            ...state,
            outletsloading: false,
          };
        }
        case OutletsActionTypes.CREATE_OUTLETS: {
          return {
            ...state,
            loading: false,
            userSignUp: true,
          };
        }
        case OutletsActionTypes.EDIT_OUTLETS: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
          };
        }
        case OutletsActionTypes.ADD_OUTLET_USERS: {
          return {
            ...state,
            outletUsersLoading: false
          };
        }
        default:
          return { ...state };
      }

    case OutletsActionTypes.API_OUTLETS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case OutletsActionTypes.LIST_OUTLETS: {
          return {
            ...state,
           
          };
        }
        case OutletsActionTypes.SEARCH_OUTLETS_BY_HOTELS: {
          return {
            ...state,
           
          };
        }
        case OutletsActionTypes.CREATE_OUTLETS: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case OutletsActionTypes.EDIT_OUTLETS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        case OutletsActionTypes.ADD_OUTLET_USERS: {
          return {
            ...state,
            outletUsersLoading: true
          };
        }
        default:
          return { ...state };
      }

    case OutletsActionTypes.RESET_OUTLETS:
      return {
        ...state,
        selectedOutletss: {}
      };
    default:
      return { ...state };
  }
};

export default OutletsReducer;
