
// constants
import { SlotsActionTypes } from "./constants";


const INIT_STATE = {
  slotsloading: false,
  slotsList: [],
  page: 0,
  limit:10,
  selectedslotDetails:{},
  listingPositionsLoading: false,
  listingSlotUsersLoading: false,
  slotUsers: [],
  positions:[]
};


interface AuthActionType {
  type:
    | SlotsActionTypes.API_SLOTS_RESPONSE_SUCCESS
    | SlotsActionTypes.API_SLOTS_RESPONSE_ERROR
    | SlotsActionTypes.LIST_SLOTS
    | SlotsActionTypes.CREATE_SLOTS
    | SlotsActionTypes.RESET_SLOTS
    | SlotsActionTypes.DELET_SLOTS
    | SlotsActionTypes.LIST_SLOT_USERS
    | SlotsActionTypes.ADD_POSITION
    | SlotsActionTypes.LIST_POSITIONS
    | SlotsActionTypes.EDIT_SLOTS_SELECTED
    | SlotsActionTypes.CREATE_SLOTS_LOADING
    | SlotsActionTypes.EDIT_SLOTS_SELECTED_LOADING
    | SlotsActionTypes.LIST_POSITIONS_LOADING
    | SlotsActionTypes.LIST_SLOT_USERS_LOADING
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  slotsloading: boolean,
  slotsList: any[],
  page: number,
  limit:number,
  selectedslotDetails:any,
  listingPositionsLoading: boolean,
  listingSlotUsersLoading: boolean,
  slotUsers: any[]
  positions: any[]
}

const SlotsReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case SlotsActionTypes.API_SLOTS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SlotsActionTypes.LIST_SLOTS_LOADING: {
          return {
            ...state,
            slotsloading: true,
          };
        }
        case SlotsActionTypes.LIST_SLOTS: {
          return {
            ...state,
            slotsloading: false,
            slotsList: action.payload.data
          };
        }
        case SlotsActionTypes.CREATE_SLOTS_LOADING: {
          return {
            ...state,
            slotsloading: true,
          };
        }
        case SlotsActionTypes.CREATE_SLOTS: {
          return {
            ...state,
            slotsloading: false,
          };
        }
        case SlotsActionTypes.EDIT_SLOTS_SELECTED_LOADING: {
          return {
            ...state,
            slotsloading: true,
            selectedslotDetails:action.payload.data
          };
        }
        case SlotsActionTypes.EDIT_SLOTS_SELECTED: {
          return {
            ...state,
            slotsloading: false,
            selectedslotDetails:action.payload.data
          };
        }
        
        case SlotsActionTypes.DELET_SLOTS: {
          return {
            ...state,
            slotsloading: false,
          };
        }
        
        case SlotsActionTypes.EDIT_SLOTS: {
          return {
            ...state,
            slotsloading: false,
          };
        }
        case SlotsActionTypes.LIST_SLOT_USERS_LOADING: {
          return {
            ...state,
            listingSlotUsersLoading: true
          };
        }
        case SlotsActionTypes.LIST_SLOT_USERS: {
          return {
            ...state,
            listingSlotUsersLoading: false,
            slotUsers: action.payload.data
          };
        }
        case SlotsActionTypes.LIST_POSITIONS_LOADING: {
          return {
            ...state,
            listingPositionsLoading: true,
          };
        }
        case SlotsActionTypes.LIST_POSITIONS: {
          return {
            ...state,
            listingPositionsLoading: false,
            positions:action.payload.data
          };
        }
        case SlotsActionTypes.ADD_POSITION: {
          return {
            ...state,
            listingPositionsLoading: false,
          };
        }
        default:
          return { ...state };
      }

    case SlotsActionTypes.API_SLOTS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SlotsActionTypes.LIST_SLOTS_LOADING: {
          return {
            ...state,
            slotsloading: true,
          };
        }
        case SlotsActionTypes.LIST_SLOTS: {
          return {
            ...state,
            slotsloading: false,
            slotsList: action.payload.data
          };
        }
        case SlotsActionTypes.CREATE_SLOTS_LOADING: {
          return {
            ...state,
            slotsloading: true,
          };
        }
        case SlotsActionTypes.CREATE_SLOTS: {
          return {
            ...state,
            slotsloading: false,
          };
        }
        case SlotsActionTypes.EDIT_SLOTS_SELECTED_LOADING: {
          return {
            ...state,
            slotsloading: true,
            selectedslotDetails:action.payload.data
          };
        }
        case SlotsActionTypes.EDIT_SLOTS_SELECTED: {
          return {
            ...state,
            slotsloading: false,
            selectedslotDetails:action.payload.data
          };
        }
        
        case SlotsActionTypes.DELET_SLOTS: {
          return {
            ...state,
            slotsloading: false,
          };
        }
        
        case SlotsActionTypes.EDIT_SLOTS: {
          return {
            ...state,
            slotsloading: false,
          };
        }
        case SlotsActionTypes.LIST_SLOT_USERS_LOADING: {
          return {
            ...state,
            listingSlotUsersLoading: true
          };
        }
        case SlotsActionTypes.LIST_SLOT_USERS: {
          return {
            ...state,
            listingSlotUsersLoading: false,
            slotUsers: action.payload.data
          };
        }
        case SlotsActionTypes.LIST_POSITIONS_LOADING: {
          return {
            ...state,
            listingPositionsLoading: true,
          };
        }
        case SlotsActionTypes.LIST_POSITIONS: {
          return {
            ...state,
            listingPositionsLoading: false,
            positions:action.payload.data
          };
        }
        case SlotsActionTypes.ADD_POSITION: {
          return {
            ...state,
            listingPositionsLoading: false,
          };
        }
        default:
          return { ...state };
      }

    case SlotsActionTypes.RESET_SLOTS:
      return {
        ...state,
        slotsloading: false,
        slotsList: [],
        page: 0,
        limit:10,
        selectedslotDetails:{},
        listingPositionsLoading: false,
        listingSlotUsersLoading: false,
        slotUsers: [],
        positions:[]
      };
    default:
      return { ...state };
  }
};

export default SlotsReducer;
