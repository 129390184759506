// import { Form } from "react-bootstrap";


export const searchFilterForGroups = 
[
    {
        key:"page"
    },
    {
        key: "limit"
    },
    {
        key: "name"
    },
    {
        key: "userStatus"
    }
]

// table columns
export const UsersTableColumns =  (data: any) => {
const arr:any= [];
 data?.data?.map((details:any) => {
   return  arr.push({
        id: details._id,
        name:details?.name,
        contactNumber: details?.contactNumber,
        type: details?.type,
        registeredDate: details?.registeredDate
      
    }) 
}) 

return arr;
}

