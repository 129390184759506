import moment from "moment";
import Table from "../../components/Table";
import Pagination from "../../components/customPaginationComponent/customPagination";
import { Card } from "react-bootstrap";

const RedeemListPage = ({
    listRedeemList,
  WalletRedeemListTableColumns,
  redeemList,
  currentPage,
  setCurrentPage,
  navigate,
  limit,
  state,
  tab,
  dispatch,
  approveRedeem
}: any) => {
  // paid list columsn
  const columns = [
    {
      Header: "ID",
      accessor: "id",
      sort:false,
    },
    {
      Header: "User",
      accessor: "user",
      sort:false,
    },
    {
      Header: "Amount",
      accessor: "amount",
      sort: false,
    },
    {
      Header: "Approved",
      accessor: "isApproved",
      sort: false,
    },
  ];
  return (
    <>
      <Card>
        <Card.Body>
          <Table
            columns={columns}
            data={WalletRedeemListTableColumns(redeemList, approveRedeem)}
            isSortable={true}
            pagination={false}
          />
        </Card.Body>
      </Card>

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={redeemList?.totalCount}
        pageSize={10}
        onPageChange={(page: any) => {
          setCurrentPage(page);
          navigate(`/wallet-request/${page}/${limit}/${tab}`);
            dispatch(
                listRedeemList({
                page: currentPage,
                limit: limit,
                
              })
            );
        }}
      />
    </>
  );
};

export default RedeemListPage;
