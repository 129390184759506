

// constants
import { GroupActionTypes } from "./constants";


const INIT_STATE = {
  // user: api.getLoggedInUser(),
  grouploading: false,
  groupsList: [],
  page:0,
  limit:5,
  selectedGroups:{},
};


interface AuthActionType {
  type:
    | GroupActionTypes.API_GROUPS_RESPONSE_SUCCESS
    | GroupActionTypes.API_GROUPS_RESPONSE_ERROR
    | GroupActionTypes.LIST_GROUPS
    | GroupActionTypes.CREATE_GROUPS
    | GroupActionTypes.RESET_GROUPS
    | GroupActionTypes.DELET_GROUPS;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  grouploading: boolean,
  groupsList: any[],
  page: number,
  limit:number,
  selectedGroups:any
}

const GroupsReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case GroupActionTypes.API_GROUPS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GroupActionTypes.LIST_GROUPS: {
          return {
            ...state,
            grouploading: false,
            groupsList: action.payload.data
          };
        }
        case GroupActionTypes.EDIT_GROUPSS_SELECTED: {
          return {
            ...state,
            grouploading: false,
            selectedGroups:action.payload.data
          };
        }
        case GroupActionTypes.DELET_GROUPS: {
          return {
            ...state,
            grouploading: false,
          };
        }
        case GroupActionTypes.CREATE_GROUPS: {
          return {
            ...state,
            loading: false,
            userSignUp: true,
          };
        }
        case GroupActionTypes.EDIT_GROUPS: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
          };
        }
        default:
          return { ...state };
      }

    case GroupActionTypes.API_GROUPS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GroupActionTypes.LIST_GROUPS: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case GroupActionTypes.CREATE_GROUPS: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case GroupActionTypes.EDIT_GROUPS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        default:
          return { ...state };
      }

    // case GroupActionTypes.DELET_GROUPS:
    //   return { ...state, loading: true, userLoggedIn: false };
    // case GroupActionTypes.LOGOUT_USER:
    //   return { ...state, loading: true, userLogout: false };
    case GroupActionTypes.RESET_GROUPS:
      return {
        ...state,
        selectedGroups: {}
      };
    default:
      return { ...state };
  }
};

export default GroupsReducer;
