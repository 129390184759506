// constants
import { GroupActionTypes } from "./constants";

export interface GroupActionType {
  type:
    | GroupActionTypes.API_GROUPS_RESPONSE_SUCCESS
    | GroupActionTypes.API_GROUPS_RESPONSE_ERROR
    | GroupActionTypes.LIST_GROUPS
    | GroupActionTypes.CREATE_GROUPS
    | GroupActionTypes.EDIT_GROUPS
    | GroupActionTypes.DELET_GROUPS
    | GroupActionTypes.RESET_GROUPS
  payload: {} | string;
}

interface GroupData {
  type: string;
  group_name: string;
  users: Array<[]>;
  hotel?: string;
  outlet?: string;
}

// common success
export const apiGroupsResponseSuccess = (
  actionType: string,
  data: GroupData | {}
): GroupActionType => ({
  type: GroupActionTypes.API_GROUPS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiGroupsResponseError = (
  actionType: string,
  error: string
): GroupActionType => ({
  type: GroupActionTypes.API_GROUPS_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingGroups = (
  searchParams: Object
): any => ({
  type: GroupActionTypes.LIST_GROUPS,
  payload: searchParams,
});

export const editSelectedGroups = (
  payload: any,
): any => ({
  type:GroupActionTypes.EDIT_GROUPSS_SELECTED,
  payload: payload,
})
export const deleteGroup =(
  id: string
): any => ({
  type:GroupActionTypes.DELET_GROUPS,
  id:id
})

export const addGroup =(
  payload: {}
): any => ({
  type:GroupActionTypes.CREATE_GROUPS,
  payload: payload
})
// export const logoutUser = (): GroupActionType => ({
//   type: GroupActionTypes.LOGOUT_USER,
//   payload: {},
// });

// export const signupUser = (
//   fullname: string,
//   email: string,
//   password: string
// ): GroupActionType => ({
//   type: GroupActionTypes.SIGNUP_USER,
//   payload: { fullname, email, password },
// });

// export const forgotPassword = (username: string): GroupActionType => ({
//   type: GroupActionTypes.FORGOT_PASSWORD,
//   payload: { username },
// });

export const resetSelectedGroups = (): any => ({
  type: GroupActionTypes.RESET_GROUPS,
  payload: {},
});
