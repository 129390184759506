import { APICore } from "./apiCore";

const api = new APICore();

interface slotsPayload{
    shiftName: string,
    startTime: string,
    endTime: string,
    hourlyPay: string,
    vacancy: string,
    release: string,
    dedicatedFilter: boolean,
    selectedPrivateGroup: [string],
    selectedPublicGroup: [string],
    subscribersView: boolean,
    priority: string,
    slotStart: string,
    allocatedUsers:[string],
    position:string  
}

interface addSlotPayload{
        slots: [slotsPayload]
        date: string,
        hotel: string,
        outlet: string,
        attendanceName: string
}
interface editSlotsPayload{
        _id : string,
        vacancy : number,
        release : number,
        priority: string,
        confirmedRequests : [string],
        waitingRequests : [string]
        
}

interface listSlotsParams{
    page: number,
    limit: number,
    startDate?: number,
    endDate?: number,
    name?: string,
    hotel?:string,
    outlet?: string
}

interface listUsersSlotsParams{
    sortOrder?: string,
    sortOn?:string,
    startDate?: string,
    endDate?:string,
}

export function listSlots(payload: any){
    const baseUrl = "slot/list"; 
    console.log('theparams',payload)
  const params =  Object.keys(payload)
            .map((key) => key + "=" +  payload[key])
            .join("&")
  return api.get(`${baseUrl}?${params}`)

}
   

export function addSlots(payload: addSlotPayload) {
    const baseUrl = "/slot/add"; 
    return api.create(`${baseUrl}`, payload);
  }

export function editSlots(payload: editSlotsPayload) {
const baseUrl = "/slot/edit"; 
return api.create(`${baseUrl}`, payload);
}

export function getSlotsById(id: string) {
const baseUrl = "slot/GetSlotDetails"; 
return api.get(`${baseUrl}/${id}`, {});
}

export function cancelSlots(id: string){
    const baseUrl = "slot/cancelSlot"; 
    return api.get(`${baseUrl}/${id}`, {}); 
}


export function listPositions(payload: any){
    const baseUrl = "slot/listPositions"; 
    console.log('theparams',payload)
    const params =  Object.keys(payload)
              .map((key) => key + "=" +  payload[key])
              .join("&")
    return api.get(`${baseUrl}?${params}`)
}

export function addPositions(payload: any){
    const baseUrl = "slot/addPosition"; 
    return api.get(`${baseUrl}`, payload); 
}

export function listUsersSlots(payload: listUsersSlotsParams){
    const baseUrl = "slot/userSlot"; 
    return api.get(`${baseUrl}`,payload); 
}

