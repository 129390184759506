// constants
import { AttendanceActionTypes } from "./constants";

export interface OutletsActionType {
  type:
    | AttendanceActionTypes.API_ATTENDANCES_RESPONSE_ERROR
    | AttendanceActionTypes.API_ATTENDANCES_RESPONSE_SUCCESS
    | AttendanceActionTypes.LIST_ATTENDANCES
    | AttendanceActionTypes.GET_ATTENDANCES
    | AttendanceActionTypes.DELET_ATTENDANCES
    | AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED
    | AttendanceActionTypes.LOCK_ATTENDANCE
    | AttendanceActionTypes.UNLOCK_ATTENDANCE
    | AttendanceActionTypes.SEND_ATTENDANCE
    | AttendanceActionTypes.GENETRATE_ATTENDANCE
    | AttendanceActionTypes.LIST_ATTENDANCES_LOADING
    | AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED_LOADING
    | AttendanceActionTypes.LOCK_ATTENDANCE_LOADING
    | AttendanceActionTypes.SEND_ATTENDANCE_LOADING
    | AttendanceActionTypes.RESET_ATTENDANCES
  payload: {} | string;
}



// common succes
export const apiOutletsResponseSucces = (
  actionType: string,
  data: any | {}
): OutletsActionType => ({
  type: AttendanceActionTypes.API_ATTENDANCES_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiOutletsResponseError = (
  actionType: string,
  error: string
): OutletsActionType => ({
  type: AttendanceActionTypes.API_ATTENDANCES_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingAttendance = (
  searchParams: Object
): OutletsActionType => ({
  type: AttendanceActionTypes.LIST_ATTENDANCES,
  payload: searchParams,
});


export const listingAttendanceById = (
  searchParams: any
): OutletsActionType => ({
  type: AttendanceActionTypes.GENETRATE_ATTENDANCE,
  payload: searchParams,
});
export const editAttendance = (
  payload: any,
): any => ({
  type:AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED,
  payload: payload,
})
export const deleteAttendance =(
  id: any
): any => ({
  type:AttendanceActionTypes.DELET_ATTENDANCES,
  id:id
})

export const LockAttendance =(id:any, details:any): any => ({
  type:AttendanceActionTypes.LOCK_ATTENDANCE,
  id:{id,details}
})
export const UnLockAttendance =(id:any,details:any): any => ({
  type:AttendanceActionTypes.UNLOCK_ATTENDANCE,
  id:{id,details}
})
export const SendAttendance =(id:any): any => ({
  type:AttendanceActionTypes.SEND_ATTENDANCE,
  id:id
})
export const GenerateAttendance =(id:any): any => ({
  type:AttendanceActionTypes.GENETRATE_ATTENDANCE,
  id:id
})


export const resetAttendance = (): OutletsActionType => ({
  type: AttendanceActionTypes.RESET_ATTENDANCES,
  payload: {},
});
