import { StaffAttendanceActionTypes } from "./constants";


const INIT_STATE = {
  staffAttendanceLoading: false,
  staffAttendanceList: [],
  page: 0,
  limit:10,
  selectedStaffAttendanceDetails:{},
  completestaffAttendanceLoading:false,
};


interface AuthActionType {
  type:
  | StaffAttendanceActionTypes.API_STAFF_ATTENDANCES_RESPONSE_ERROR
  | StaffAttendanceActionTypes.API_STAFF_ATTENDANCES_RESPONSE_SUCCESS
  | StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES
  | StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES_LOADING
  | StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED
  | StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED_LOADING
  | StaffAttendanceActionTypes.COMPLETE_ATTENDANCE
  | StaffAttendanceActionTypes.COMPLETE_ATTENDANCE_LOADING
  | StaffAttendanceActionTypes.RESET_STAFF_ATTENDANCES
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  staffAttendanceLoading: boolean,
  staffAttendanceList: any[],
  page: number,
  limit:number,
  selectedStaffAttendanceDetails:any,
  completestaffAttendanceLoading:any,
}

const StaffAttendanceReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case StaffAttendanceActionTypes.API_STAFF_ATTENDANCES_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES_LOADING: {
          return {
            ...state,
            staffAttendanceLoading: true,
          };
        }
        case StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES: {
          return {
            ...state,
            staffAttendanceLoading: false,
            staffAttendanceList: action.payload.data
          };
        }
        
        case StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED_LOADING: {
          return {
            ...state,
            staffAttendanceLoading: false,
          };
        }
        
        case StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED: {
          return {
            ...state,
            staffAttendanceLoading: false,
          };
        }
        case StaffAttendanceActionTypes.COMPLETE_ATTENDANCE: {
          return {
            ...state,
            listingPositionsLoading: false,
          };
        }
        case StaffAttendanceActionTypes.COMPLETE_ATTENDANCE_LOADING: {
          return {
            ...state,
            completestaffAttendanceLoading: true,
          };
        }
       
        default:
          return { ...state };
      }

    case StaffAttendanceActionTypes.API_STAFF_ATTENDANCES_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES_LOADING: {
          return {
            ...state,
            staffAttendanceLoading: true,
          };
        }
        case StaffAttendanceActionTypes.LIST_STAFF_ATTENDANCES: {
          return {
            ...state,
            staffAttendanceLoading: false,
            staffAttendanceList: action.payload.data
          };
        }
        case StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED: {
          return {
            ...state,
            staffAttendanceLoading: false,
          };
        }
        case StaffAttendanceActionTypes.EDIT_STAFF_ATTENDANCES_SELECTED_LOADING: {
          return {
            ...state,
            staffAttendanceLoading: false,
          };
        }
        
        case StaffAttendanceActionTypes.COMPLETE_ATTENDANCE_LOADING: {
          return {
            ...state,
            listingPositionsLoading: false,
          };
        }
        case StaffAttendanceActionTypes.COMPLETE_ATTENDANCE: {
          return {
            ...state,
            completestaffAttendanceLoading: true,
          };
        }
       
        default:
          return { ...state };
      }

    case StaffAttendanceActionTypes.RESET_STAFF_ATTENDANCES:
      return {
        ...state,
        staffAttendanceLoading: false,
        staffAttendanceList: [],
        page: 0,
        limit:10,
        selectedStaffAttendanceDetails:{},
        completestaffAttendanceLoading:false,
      };
    default:
      return { ...state };
  }
};

export default StaffAttendanceReducer;
