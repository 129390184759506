
// constants
import { HotelActionTypes } from "./constants";

const INIT_STATE = {
  hotelsloading: false,
  hotelsList: [],
  page: 0,
  limit:10,
  selectedHotels:{},
  hotelsEditingLoading: false
};


interface AuthActionType {
  type:
    | HotelActionTypes.API_HOTELS_RESPONSE_SUCCESS
    | HotelActionTypes.API_HOTELS_RESPONSE_ERROR
    | HotelActionTypes.LIST_HOTELS
    | HotelActionTypes.CREATE_HOTELS
    | HotelActionTypes.RESET_HOTELS
    | HotelActionTypes.DELET_HOTELS;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  hotelsloading: boolean,
  hotelsList: any[],
  page: number,
  limit:number,
  selectedHotels:any,
  hotelsEditingLoading: boolean
}

const HotelsReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case HotelActionTypes.API_HOTELS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case HotelActionTypes.LIST_HOTELS: {
          return {
            ...state,
            hotelsloading: false,
            hotelsList: action.payload.data
          };
        }
        case HotelActionTypes.EDIT_HOTELSS_SELECTED: {
          return {
            ...state,
            hotelsloading: false,
            selectedHotels:action.payload.data
          };
        }
        case HotelActionTypes.DELET_HOTELS: {
          return {
            ...state,
            hotelsloading: false,
          };
        }
        case HotelActionTypes.CREATE_HOTELS: {
          return {
            ...state,
            loading: false,
            userSignUp: true,
          };
        }
        case HotelActionTypes.EDIT_HOTELS: {
          return {
            ...state,
            selectedHotels:{},
            hotelsEditingLoading: false
          };
        }
        default:
          return { ...state };
      }

    case HotelActionTypes.API_HOTELS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case HotelActionTypes.LIST_HOTELS: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case HotelActionTypes.CREATE_HOTELS: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case HotelActionTypes.EDIT_HOTELS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        default:
          return { ...state };
      }

    case HotelActionTypes.RESET_HOTELS:
      return {
        ...state,
        selectedHotels: {}
      };
    default:
      return { ...state };
  }
};

export default HotelsReducer;
