import { APICore } from "./apiCore";


const api = new APICore();

// account
function listUsers(payload: any) {
  let baseUrl = `user/getUsers`
  const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
        
    return api.get(`${baseUrl}?${params}`)
}
function listusersById(id: any) {
  console.log("te id", id)
  const baseUrl = "user/getUser";
  return api.get(`${baseUrl}/${id}`,{});
}

function listInstitutions(searchParams: any){
  const baseUrl = "user/institutions";
  return api.get(`${baseUrl}`,{searchParams})
}

function creatUsers(payload: any) {
  const baseUrl = "support/add/";
  return api.create(`${baseUrl}`, payload);
}

function editUsers(params: any) {
  const baseUrl = `user/updateProfile`;
  return api.updatePatch(`${baseUrl}`, params);
}
function editUsersStatus(params: any) {
  const baseUrl = `user/update`;
  return api.updatePatch(`${baseUrl}`,params );
}

function deleteUsers(id: any) {
  const baseUrl = `user/delete/${id}`;
  return api.updatePatch(`${baseUrl}`,{});
}

export { listUsers, editUsersStatus, creatUsers, editUsers, deleteUsers , listusersById, listInstitutions};
