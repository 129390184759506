import { AttendanceActionTypes } from "./constants";


const INIT_STATE = {
  attendanceloading: false,
  attendanceList: [],
  page: 0,
  limit:10,
  selectedAttendanceDetails:{},
  lockAttendanceLoading:false,
  sendAttendanceLoading:false
};


interface AuthActionType {
  type:
  | AttendanceActionTypes.API_ATTENDANCES_RESPONSE_ERROR
  | AttendanceActionTypes.API_ATTENDANCES_RESPONSE_SUCCESS
  | AttendanceActionTypes.LIST_ATTENDANCES
  | AttendanceActionTypes.GET_ATTENDANCES
  | AttendanceActionTypes.DELET_ATTENDANCES
  | AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED
  | AttendanceActionTypes.LOCK_ATTENDANCE
  | AttendanceActionTypes.UNLOCK_ATTENDANCE
  | AttendanceActionTypes.SEND_ATTENDANCE
  | AttendanceActionTypes.GENETRATE_ATTENDANCE
  | AttendanceActionTypes.LIST_ATTENDANCES_LOADING
  | AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED_LOADING
  | AttendanceActionTypes.LOCK_ATTENDANCE_LOADING
  | AttendanceActionTypes.SEND_ATTENDANCE_LOADING
  | AttendanceActionTypes.RESET_ATTENDANCES
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  attendanceloading: boolean,
  attendanceList: any[],
  page: number,
  limit:number,
  selectedAttendanceDetails:any,
  lockAttendanceLoading:any,
  sendAttendanceLoading:any
}

const SlotsReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case AttendanceActionTypes.API_ATTENDANCES_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AttendanceActionTypes.LIST_ATTENDANCES_LOADING: {
          return {
            ...state,
            attendanceloading: true,
          };
        }
        case AttendanceActionTypes.LIST_ATTENDANCES: {
          return {
            ...state,
            attendanceloading: false,
            attendanceList: action.payload.data
          };
        }
        case AttendanceActionTypes.GET_ATTENDANCES: {
          return {
            ...state,
            attendanceloading: false,
            selectedAttendanceDetails:action.payload.data
          };
        }
        case AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED: {
          return {
            ...state,
            attendanceloading: false,
          };
        }
        
        case AttendanceActionTypes.DELET_ATTENDANCES: {
          return {
            ...state,
            attendanceloading: false,
          };
        }
        case AttendanceActionTypes.LOCK_ATTENDANCE: {
          return {
            ...state,
            listingPositionsLoading: false,
          };
        }
        case AttendanceActionTypes.LOCK_ATTENDANCE_LOADING: {
          return {
            ...state,
            lockAttendanceLoading: true,
          };
        }
        case AttendanceActionTypes.SEND_ATTENDANCE_LOADING: {
          return {
            ...state,
            sendAttendanceLoading: true,
          };
        }
        case AttendanceActionTypes.SEND_ATTENDANCE: {
          return {
            ...state,
            lockAttendanceLoading: false,
          };
        }
        case AttendanceActionTypes.GENETRATE_ATTENDANCE: {
          return {
            ...state,
            lockAttendanceLoading: false,
          };
        }
        default:
          return { ...state };
      }

    case AttendanceActionTypes.API_ATTENDANCES_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AttendanceActionTypes.LIST_ATTENDANCES_LOADING: {
          return {
            ...state,
            attendanceloading: true,
          };
        }
        case AttendanceActionTypes.LIST_ATTENDANCES: {
          return {
            ...state,
            attendanceloading: false,
            attendanceList: action.payload.data
          };
        }
        case AttendanceActionTypes.GET_ATTENDANCES: {
          return {
            ...state,
            attendanceloading: false,
            selectedAttendanceDetails:action.payload.data
          };
        }
        case AttendanceActionTypes.EDIT_ATTENDANCES_SELECTED: {
          return {
            ...state,
            attendanceloading: false,
          };
        }
        case AttendanceActionTypes.DELET_ATTENDANCES: {
          return {
            ...state,
            attendanceloading: false,
          };
        }
        
        case AttendanceActionTypes.LOCK_ATTENDANCE: {
          return {
            ...state,
            listingPositionsLoading: false,
          };
        }
        case AttendanceActionTypes.LOCK_ATTENDANCE_LOADING: {
          return {
            ...state,
            lockAttendanceLoading: true,
          };
        }
        case AttendanceActionTypes.SEND_ATTENDANCE_LOADING: {
          return {
            ...state,
            sendAttendanceLoading: true,
          };
        }
        case AttendanceActionTypes.SEND_ATTENDANCE: {
          return {
            ...state,
            lockAttendanceLoading: false,
          };
        }
        case AttendanceActionTypes.GENETRATE_ATTENDANCE: {
          return {
            ...state,
            lockAttendanceLoading: false,
          };
        }
        default:
          return { ...state };
      }

    case AttendanceActionTypes.RESET_ATTENDANCES:
      return {
        ...state,
        attendanceloading: false,
        attendanceList: [],
        page: 0,
        limit:10,
        selectedAttendanceDetails:{},
        lockAttendanceLoading:false,
        sendAttendanceLoading:false
      };
    default:
      return { ...state };
  }
};

export default SlotsReducer;
