import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UsersList from './UsersList';
import { searchFilterForGroups } from './data';
import UsersEditForm from './userEditForm';


const Users = () => {

    return(
        <>
        <Routes>
          <Route path="/" element={<UsersList />} />
          <Route 
          path={`/:${searchFilterForGroups[0].key}/:${searchFilterForGroups[1].key}/:${searchFilterForGroups[2].key}/:${searchFilterForGroups[3].key}`}
          element={<UsersList />} />
                     <Route 
          path={`edit/:id`}
          element={<UsersEditForm />} />
           <Route 
          path={`?delete=`}
          element={<UsersList />} />
           <Route 
          path={`?add=`}
          element={<UsersList />} />
        </Routes>
        </>
    )
}

export default Users;