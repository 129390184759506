// constants
import { SlotsActionTypes } from "./constants";

export interface OutletsActionType {
  type:
    | SlotsActionTypes.API_SLOTS_RESPONSE_SUCCESS
    | SlotsActionTypes.API_SLOTS_RESPONSE_ERROR
    | SlotsActionTypes.LIST_SLOTS
    | SlotsActionTypes.CREATE_SLOTS
    | SlotsActionTypes.LIST_POSITIONS_LOADING
    | SlotsActionTypes.DELET_SLOTS
    | SlotsActionTypes.CREATE_SLOTS_LOADING
    | SlotsActionTypes.EDIT_SLOTS
    | SlotsActionTypes.EDIT_SLOTS_SELECTED
    | SlotsActionTypes.EDIT_SLOTS_SELECTED_LOADING
    | SlotsActionTypes.ADD_POSITION
    | SlotsActionTypes.LIST_POSITIONS
    | SlotsActionTypes.LIST_POSITIONS_LOADING
    | SlotsActionTypes.LIST_SLOT_USERS
    | SlotsActionTypes.LIST_SLOT_USERS_LOADING
    | SlotsActionTypes.RESET_SLOTS
  payload: {} | string;
}



// common succes
export const apiOutletsResponseSucces = (
  actionType: string,
  data: any | {}
): OutletsActionType => ({
  type: SlotsActionTypes.API_SLOTS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiOutletsResponseError = (
  actionType: string,
  error: string
): OutletsActionType => ({
  type: SlotsActionTypes.API_SLOTS_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listingSlots = (
  searchParams: Object
): any => ({
  type: SlotsActionTypes.LIST_SLOTS,
  payload: searchParams,
});

export const createSlots = (
  searchParams: any
): any => ({
  type: SlotsActionTypes.CREATE_SLOTS,
  payload: searchParams,
});

export const listingSlotsById = (
  searchParams: any
): any => ({
  type: SlotsActionTypes.EDIT_SLOTS_SELECTED,
  payload: searchParams,
});
export const editSlots = (
  payload: any,
): any => ({
  type:SlotsActionTypes.EDIT_SLOTS,
  payload: payload,
})
export const deleteSlots =(
  id: any
): any => ({
  type:SlotsActionTypes.DELET_SLOTS,
  id:id
})

export const ListPositions =(details: any): any => ({
  type:SlotsActionTypes.LIST_POSITIONS,
  details: details
})

export const AddPositions =(
details: any
): any => ({
  type:SlotsActionTypes.ADD_POSITION,
  details:details
})

export const ListSlostUsers =(
  details: any
  ): any => ({
    type:SlotsActionTypes.LIST_SLOT_USERS,
    details:details
  })

export const resetSlots = (): any => ({
  type: SlotsActionTypes.RESET_SLOTS,
  payload: {},
});
