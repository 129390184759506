import React, { useEffect, useState } from "react";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import Table from "../../components/Table";

//dummy data
// import { records as data, expandableRecords } from "../tables/data";
// import FontAwesomeIcons from "../icons/FontAwesomeIcons";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, listingUsers } from "../../redux/users/actions";
import { RootState } from "../../redux/store";
import { UsersTableColumns } from "./data";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import CommonSearchBar from "../../layouts/SearchBar";
import Pagination from "../../components/customPaginationComponent/customPagination";




const UsersList = () => {
  const {name, userStatus, limit,page } = useParams();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const deleteParam = query.get('delete');
  const [responsiveModal, setResponsiveModal] = useState<boolean>((deleteParam) ? true : false);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sort:false,
    },
    {
      Header: "Phone Number",
      accessor: "contactNumber",
      sort: false,
    },
    {
      Header: "LOCAL/FOREGINER",
      accessor: "type",
      sort:false,
    },
    {
      Header: "Registered Date",
      accessor: "registeredDate",
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      Cell: ({ cell}: any) => (
        <>
       <span  onClick={() => handleEditGroup(cell.row.original)}>
              <i className="bi bi-pencil-square"></i>
          </span> &nbsp;<span onClick={() => handleDeleteGroup(cell.row.original)}>
          <i className="bi bi-trash3"></i>
              </span> 
        </>
      )
    },
    {
      Header: " ",
      accessor: "chat",
      Cell: ({cell}:any) => (
        <>
        <span style={{fontSize: 'x-large',
    color: 'green',
    cursor:'pointer'}}>
                 <i onClick={() => window.open(`https://wa.me/${cell.row.original.contactNumber}`, "_blank")}
                        className={classNames(
                          "fab",
                          "fa-whatsapp",
                          "mdi-account"
                        )}
                      ></i>
     </span>
        </>
      )
    }
  ];
  const {usersList}  = useSelector((state:RootState) => state.UsersReducer)

  const handleEditGroup = (cell: any) => {
    console.log("the cell in edit", cell)
    navigate(`/users/list/edit/${cell.id}`)
  }

  const handleDeleteGroup = (cell: any) => {
     console.log('tp delete', cell)
     navigate(`?delete=${cell.id}`);
    setResponsiveModal(!responsiveModal);
  }
  
    const navigate = useNavigate();
  const dispatch = useDispatch()
  useEffect(() => {
    if(name === "all"){
      dispatch(listingUsers({userStatus: userStatus === "all" ? "REGISTERED" : userStatus,page: currentPage,limit: limit}))
    }
    else{
      dispatch(listingUsers({userStatus: userStatus === "all" ? "REGISTERED" : userStatus,page: currentPage,limit: limit, name: name}))
    }
     },[userStatus, deleteParam])

  const searchUsers =(e:any) => {
    if(!e.target.value){
     
      navigate(`/users/list/${page}/${limit}/all/${userStatus === "all" ? "REGISTERED" : userStatus}`);
    }
    else{
      navigate(`/users/list/${page}/${limit}/${e.target.value}/${userStatus === "all" ? "REGISTERED" : userStatus}`);
    }
   
    dispatch(listingUsers({userStatus: userStatus === "all" ? "REGISTERED" : userStatus, name: e.target.value, page: currentPage,limit: 10}))
  }
  const filterValueChangeByUsersStatus = (name: any, e: any) => {
    console.log("thhe status", e[0]?.value)
    dispatch(listingUsers({userStatus: e[0]?.value}))
 }

 const searchByName = (keyName: any,e: any) => {
  console.log("thje e", e)
  if(e === "all") {
    navigate(`/users/list/${page}/${limit}/all/all`);
    // dispatch(listingUsers({userStatus: 'all', page: currentPage,limit: 10})) 
  }
  if(e !== " "){
    navigate(`/users/list/${page}/${limit}/all/${e === "all" ? "REGISTERED" : e}`);
    dispatch(listingUsers({userStatus: e === "all" ? "REGISTERED" : e, page: currentPage,limit: 10}))  
  }
  
   
}
const toggleResponsiveModal = (values: any) => {
  query.delete("delete")
  query.delete("edit")
  dispatch(listingUsers({userStatus: userStatus === "all" ? "REGISTERED" : userStatus, page: currentPage,limit: 10}))  
  // navigate(`/support`);
  navigate(-1);
  setResponsiveModal(!responsiveModal);
  };
  const deleteEvent = () => {
    dispatch(deleteUser(deleteParam ?  deleteParam : undefined))
    query.delete("delete");
    setResponsiveModal(!responsiveModal);
    navigate(-1);
  } 
  const [currentPage, setCurrentPage] = useState(page)

    return(
        <div>
            <h1>Users</h1>
            <CommonSearchBar
          name={name}
          searchTitle={"Search Users..."}
          search={(e:any) => searchUsers(e)}
          searchByName={(filetr: any, e: any) => searchByName(filetr,e)}
          filterValueChanged={filterValueChangeByUsersStatus}
          defaultoptionValue={userStatus}
          filters={
            [
              {label: "User Status",
              name: "userStatus",
              // placeholder:"Please select a user Status",
              isSelect: false,
            
              options:
              [ 
                {id: 0, value: "all", label: "Please select a user Status"},
                {id: 1, value: "REGISTERED", label: "USER REGISTERED"},
                {id: 2, value: "PENDING", label:"DETAILS SUBMITTED/ PENDING VERIFICATION"},
                {id: 3, value: "VERIFIED", label: "VERIFIED ACCOUNT"},
                {id: 4, value: "SUSPENDED", label: "ACCOUNT SUSPEND - NO LOGIN"},

                ]
              },
            ]
          }
          />
           <Row>
        <Col>
      <Modal show={responsiveModal} onHide={() => toggleResponsiveModal('test')}>
      <Modal.Body className="p-2">
      <div>
      <h3>Are you sure you want to delete the selected user?</h3>
      <div className="text-end">
      <Button
      variant="success"
      type="submit"
      className="waves-effect waves-light me-1"
      onClick={() => deleteEvent()}
      >
      Save
      </Button>
      <Button
      variant="danger"
      className="waves-effect waves-light"
      onClick={toggleResponsiveModal}
      >
      Close
      </Button>
      </div>
        </div>
                </Modal.Body>
              </Modal>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={UsersTableColumns(usersList)}
                isSortable={true}
              />
               <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={usersList?.totalCount}
        pageSize={5}
        onPageChange={(page: any) => {
          setCurrentPage(page)
          navigate(`/users/list/${page}/${limit}/${name}/${userStatus === "all" ? "REGISTERED" : userStatus}`);
          dispatch(listingUsers({userStatus: userStatus === "all" ? "REGISTERED" : userStatus,page: page,limit: limit}))
        }}
      />
            </Card.Body>
          </Card>
        </Col>
      </Row>
        </div>
    )
}

export default UsersList;