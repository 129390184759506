import { APICore } from "./apiCore";

const api = new APICore();



export function listAttendances(payload: any){
    const baseUrl = "attendance/list"; 
    const params =  Object.keys(payload)
            .map((key) => key + "=" + payload[key])
            .join("&")
    return api.get(`${baseUrl}?${params}`)
}

export function getAttendanceById(id: any){
    const baseUrl = "attendance/getAttendance"; 
    return api.get(`${baseUrl}/${id}`)
}
export function updateAttendanceById(details: any){
    const baseUrl = "attendance/update"; 
    return api.update(`${baseUrl}/${details.id}/${details.status}`,{})
}

export function deletAttendanceById(id: any){
    const baseUrl = "attendance/delete"; 
    return api.updatePatch(`${baseUrl}/${id}`,{})
}

export function lockAttendanceById(id: any){
    const baseUrl = "attendance/lock"; 
    return api.updatePatch(`${baseUrl}/${id}`,{})
}
export function unlockAttendanceById(id: any){
    const baseUrl = "attendance/unlock"; 
    return api.updatePatch(`${baseUrl}/${id}`,{})
}

export function sendAttendanceById(id: any){
    const baseUrl = "attendance/send"; 
    return api.update(`${baseUrl}/${id}`,{})
}

export function generateAttendanceById(id: any){
    const baseUrl = "attendance/list"; 
    return api.get(`${baseUrl}`)
}



